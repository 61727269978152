export const closeFunc = (e, item) => {
    e.preventDefault();

    const itemClose = item.closest('.item-close');
    const swiperTabsWrap = item.closest('.swiper-tabs-wrap');

    if (itemClose) {
        document.body.classList.remove('over-y-hide');

        if (!itemClose.classList.contains('hint')) setTimeout(() => itemClose.classList.add('height'), 500);

        if (!itemClose.classList.contains('modal')) {
            itemClose.classList.add('hidden');
            if (!itemClose.classList.contains('no-remove')) {
                setTimeout(() => {
                    itemClose.remove();
                    $('body').trigger('update:totalandprofit');
                }, 600);
            }
        } else {
            itemClose.classList.remove('show');
            setTimeout(() => itemClose.classList.remove('open'), 300);
            if (!itemClose.classList.contains('no-remove')) {
                setTimeout(() => itemClose.remove(), 600);
            }
        }

        if (swiperTabsWrap) {
            const swiperInstance = swiperTabsWrap.swiper;
            setTimeout(() => swiperInstance.updateAutoHeight(), 601);
        }
    }
};