import {new_swiper} from "./sliders.js";
//import Swiper from 'swiper/swiper.js';

export const swiperTabsList = (on) => {
    const swiper = document.querySelector('.swiper-tabs-list');
    // debugger;
    if (!swiper) return false;
    const s = new Swiper(swiper, {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        on
    });


    setTimeout(() => {
        // s.slideTo(s.activeIndex, 400);
        let slide_index = -1;
        for (const [i, slide] of Object.entries(s.slides)) {
            if (slide.classList.contains('swiper-slide-thumb-active')) {
                slide_index = i;
                break;
            }
        }
        //console.log('here ooooo', s.slides, slide_index);
        // slide_index = 0;
        if (slide_index != -1) s.slideTo(slide_index, 400);
    }, 500);
    
    new_swiper(s);
    return s;
};