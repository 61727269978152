export const rangeFunc = (item, sync = true) => {
    //check for number
    if (isNaN(item.value)) item.value = 0;

    const parent = item.closest('.bet__range');

    if (parent) {
        const inputRangeValue = parent.querySelector('.input-range-value');
        const rangeInput = parent.querySelector('.bet__range-input');
        const max = +item.getAttribute('max')? +item.getAttribute('max') : Number.MAX_SAFE_INTEGER;
        const min = +item.getAttribute('min') ? +item.getAttribute('min') : 0;

        if (sync) inputRangeValue.value = item.value;

        if (item.value == min) rangeInput.setAttribute('data-range', 'start');
        if (item.value == max) rangeInput.setAttribute('data-range', 'end');
        if (item.value > min && item.value < max) rangeInput.setAttribute('data-range', 'center');

        //trigger change event
        $('input.input-range').change()
    }
};