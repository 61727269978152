// import {wsSubscribe} from "./ws.js";
import {color} from "./helpers/color.js";
import {pages} from "./pages/pages.js";
import {sliders, swiper_update_height} from "./sliders/sliders.js";
import {media} from "./helpers/media.js";
import {eventDocument} from "./helpers/eventDocument.js";
import {littleThings} from "./little-things.js";
import {rangeInputFuncInit} from "./range/rangeInputFunc.js";
import {alertFuncPreloadInit} from "./alert/alertFunc.js";
import {toSVG} from "./helpers/toSVG.js";
import './helpers/js_form.js'; 
import { initFilters } from "./helpers/gameFilters.js";
import { initCheckboxes } from "./helpers/checkbox.js";
//import * as inputmask from "./helpers/inputmask.js";
//import * as fpr from "./fpr.js"
import {grecaptchaCallback} from "./valid/grecaptcha.js";


// window.wsSubscribe = wsSubscribe;
window.color =  color;
window.lang_js = $('body').data('lang-js');
window.toSVG = toSVG;
window.grecaptchaCallback = grecaptchaCallback;

document.addEventListener('DOMContentLoaded', () => {
    sliders();
    //window.swiper_update_height = swiper_update_height;
    media();
    eventDocument();
    pages();
    littleThings();
    window.littleThings = littleThings;
    var content = document.getElementById('content');
    rangeInputFuncInit();
    alertFuncPreloadInit();
    initFilters();
    initCheckboxes();



    window.items = () => console.log('items call try');//items;

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service_worker.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    }



    import(/* webpackPreload: true */ './google.js')
        .catch(err => {
            console.error("google load error", err);
        });


    if ($("input.phone_js_mask").length || $("input.card_ValidDate_js_mask").length || $("input.card_number_js_mask").length || $("input.webmoney_js_mask").length) {
        import(/* webpackPreload: true */ './helpers/inputmask.js')
            .catch(err => {
                console.error("inputmask load error", err);
            });
    }
    
    if (typeof fpr !== "undefined" && fpr) {
        import(/* webpackPreload: true */ './fpr.js')
        .catch(err => {
            console.error("fpr load error", err);
        });
    }

    if (typeof wsToken !== "undefined" &&   wsToken) {
        import(/* webpackPreload: true */ './ws.js')
        .then(ws => {
            ws.wsSubscribe(wsToken);
        })
        .catch(err => {
            console.error("ws load error", err);
        });
        
    }
});