import {new_swiper} from "./sliders.js";
import {tabFunc} from "../tab/tabFunc.js";
//import Swiper from 'swiper/swiper.js';

export const swiperTab = (on) => {
    const swiper = document.querySelectorAll('.swiper-tab');
    if (!swiper.length) return false;

    swiper.forEach(function (container) {
        if (container.innerHTML.trim() != "") {
            const swiper = new Swiper(container, {
                // initialSlide: 0,
                slidesPerView: 'auto',
                freeMode: true,
                roundLengths: true,
                on
            });
            new_swiper(swiper);
            // swiper.slideTo(1, 400);
    
            const swiperTabSlides = container.querySelectorAll('.swiper-slide');
            let initTab = 0;
            swiperTabSlides.forEach(function (slide, index) {
                slide.style.zIndex = -index;
                if (slide.querySelector('[name="games-list"]:checked')) swiper.slideTo(index, 400);
                
                slide.addEventListener('click', function () {
                    // if (slide.href) location.href = slide.href;
                    swiper.slideTo(index, 400);
                });
    
                if ('#'+slide.getAttribute('anchor') == location.hash || slide.getAttribute('href') == location.hash || slide.classList.contains('prechoosed')) {
                    swiper.slideTo(index, 400);
                    initTab = index;
                }

                if (slide.tagName == 'A')  slide.addEventListener('click', () => location.href = slide.href);
            });
    
            if (!container.querySelector('.swiper-slide.active') && swiperTabSlides[0] && swiperTabSlides[0].classList.contains('js-tab')) {
                // console.log('needs prechoose')
                swiper.slideTo(initTab, 400);
                tabFunc(null, swiperTabSlides[initTab]);
            }
    
            swiper.on('slideChangeTransitionEnd', function () {
                console.log('slideChange');
            });
            // if (swiper.activeIndex)
        }
    });
};