import {swiperScroll} from "./swiperScroll.js";
import {swiperTab} from "./swiperTab.js";
import {onObject} from "./onObject.js";
import {swiperTabsList} from "./swiperTabsList.js";
import {swiperTabsWrap} from "./swiperTabsWrap.js";
import {swiperSlideChange} from "./swiperSlideChange.js";
import {swiperAchievement} from "./swiperAchievement.js";
import {swiperFilter} from "./swiperFilter.js";

import {swiperOnInitChoose} from "./swiperOnInitChoose.js";

let all_swipers = [];
// window.all_swipers = all_swipers; //only for debug purposes

export const sliders = () => {
    const on = onObject();
    let tabsList = swiperTabsList(on);
    let tabsWrap = swiperTabsWrap();

    let swiper_scroll = swiperScroll();
    let swiper_fileter = swiperFilter(on);
    let swipers_tab_array = swiperTab(on);
    let swiper_achievements = swiperAchievement(on);

    //check if siper exists then add to array
    // if (swiper_scroll) all_swipers.push(swiper_scroll);
    // if (swiper_fileter) all_swipers.push(swiper_fileter);
    // if (swipers_tab_array) all_swipers.push(swipers_tab_array);
    // if (swiper_achievements) all_swipers.push(swiper_achievements);
    // if (tabsWrap) all_swipers.push(tabsWrap);
    // if (tabsList) all_swipers.push(tabsList);

    if (tabsList && tabsWrap) swiperSlideChange(tabsList, tabsWrap);

    if (tabsList && tabsWrap) swiperOnInitChoose(tabsList, tabsWrap);
};

const swiper_speed = 300;
export const swiper_update_height = () => {
    all_swipers.forEach((swiper) => {
        if (swiper && !swiper.destroyed) swiper.updateAutoHeight(swiper_speed);
    });
};

export const new_swiper = (swiperObj) => {
    all_swipers.push(swiperObj);
};

// window.swiper_update_height = swiper_update_height; //only for debug purposes