import {rangeFunc} from "./rangeFunc.js";

export const rangeInputFunc = (item) => {
    //check for is number
    if (isNaN(item.value) || item.value=='') item.value = 0;
    const val = item.value;
    
    const min = +item.getAttribute('min');
    const max = +item.getAttribute('max');
    const range = item.closest('.bet__range');
    const inputRange = range.querySelector('.input-range');

    if (val > max) item.value = max;
    if (val < min) item.value = min;
    if (val || (min <= 0 && val == 0)) {
        inputRange.value = item.value;
        rangeFunc(inputRange, false);
    }
};

export const rangeInputFuncInit = () => {
    const rangeInputs = document.querySelectorAll('.input-range-value');
    if (rangeInputs.length) {
        rangeInputs.forEach(item => {
            rangeInputFunc(item);
        });
    }
};