import { countTotalAllDivs } from "./countTotal.js";

$('body').on('click', '.form_for_js_in_div .button_for_js_in_div', function (event) {
    var button = $(this);
    var button_name = button.attr('name');
    var formdiv = $(this).closest('.form_for_js_in_div');
    var action = formdiv.data('action');
    //console.log(action);

    var post_data = {};
    //add all inputs to post_data
    var inputs = formdiv.find('input');
    //console.log(inputs);
    for (var i = 0; i < inputs.length; i++) {
        const input = inputs.eq(i);
        // console.log(input)
        if (input.attr(`type`) == `radio` || input.attr(`type`) == `checkbox`) {
            // console.log(input);
            // console.log(input.prop("checked"));
            if (!input.prop("checked")) {//если не чекед то не отправляем
                // console.log(`not checked`);
                continue;
            }
        }

        var inputname = input.attr('name');
        if (inputname == 'undefined' || inputname == undefined) {
            continue;
        }
        var inputvalue = input.val();
        if (inputname && inputname.includes('[]')) {
            if (post_data[inputname] == undefined) {
                post_data[inputname] = [];
            }
            post_data[inputname].push(inputvalue);
        } else {
            //reverse for ip_protect
            if (inputname == 'ip_protect') {
                inputvalue = inputvalue == '1' ? '0' : '1';
            }
            post_data[inputname] = inputvalue;
        }
    }

    //add all selects to post_data
    var selects = formdiv.find('select');
    for (var i = 0; i < selects.length; i++) {
        const select = selects.eq(i);
        var selectname = select.attr('name');
        var selectvalue = select.val();
        if (selectname && selectname.includes('[]')) {
            if (post_data[selectname] == undefined) {
                post_data[selectname] = [];
            }
            post_data[selectname].push(selectvalue);
        } else {
            post_data[selectname] = selectvalue;
        }
    }


    var recaptcha = formdiv.find('textarea.g-recaptcha-response');
    if (recaptcha) {
        post_data['g-recaptcha-response'] = recaptcha.val();
    }
    //var post_data;

    post_data.button_name = button_name;
    // console.log('https://' + window.location.host + action);
    // console.log(post_data);
    //перед отправкой отключаем кнопку и добавляем анимацию лоада
    button.prop("disabled", true);
    if (button.hasClass('animation-loading')) {
        button.addClass('loading');
    }
    if (button.hasClass('hide-loading')) {
        button.hide();
    }
    let url = 'https://' + window.location.host + action;
    //alert(JSON.stringify([url, post_data])); 
    var posting = $.post(url, post_data);
    posting.done(function (data, textStatus, request) {
        try {
            if (data.error) {
                $('body').append(data.error);
                return;
            }
            //alert(JSON.stringify(data)); 
            // console.log(formdiv);
            //console.log(JSON.parse(data));
            // console.log(request);
            // console.log(action);
            $('.response_block_close_js_answer').remove();
            $('div.blackout').remove();
            //console.log($('.response_block_close_js_answer'));
            //console.log($('div.blackout'));
            //unusual behavior for market
            if (action == '/engine/function/user_trades_post.php') {

                //sell item
                if (formdiv.hasClass('modal') && post_data.trade_type == 'sell') {
                    //console.log(JSON.stringify(data));
                    if (data.response && data.response.trade) {
                        $('#js__sell_up').closest('.bet-content').show();
                        $(`input[name=assetid][value=${post_data.assetid}]`).closest('.pay__item.setting').remove();
                        $('#js__sell_up').find('.bet-content-show').first().append(data.response.trade);
                        formdiv.find('a.js-close')[0].click();
                        countTotalAllDivs();
                        return;
                    } else {
                        //console.log('no response');
                        return;
                    }
                }


                //update item price
                if (formdiv.hasClass('modal') || post_data['cancel_item_selling']) {
                    //console.log(JSON.stringify(data));
                    if (!data.response) {
                        //console.log('GIMME GOOD RESPONSE');
                        return;
                    }
                    if (!data.response.user_trade_id) {
                        //console.log('no trade id lal');
                        return;
                    }
                    if (!data.response.price) {
                        //console.log('0 price')
                        $(`input[value="${data.response.user_trade_id}"]`).closest('.pay__item.setting').remove();
                        formdiv.find('a.js-close')[0].click();
                        countTotalAllDivs();
                        return;
                    }
                    //console.log('updating price');
                    let $item_div = $(`input[value="${data.response.user_trade_id}"]`).closest('.pay__item.setting');
                    $item_div.find('[data-price]').data('price', data.response.price);
                    $item_div.find('.pay__item-sum .money-amount .js_amount').text(data.response.price);
                    formdiv.find('a.js-close')[0].click();
                    countTotalAllDivs();
                    return;
                }

                
                
            }


            if (action.includes('/engine/function/give_achievement_bonus.php')) {
                formdiv.find('.js_response_to_div').html(data);
                return;
            }
            // if (action.includes('/engine/function/payments_post.php')) {
            //     try {
            //         console.log(data);
            //         window.bitcart.showInvoice(data);
            //     } catch (error) {
            //         console.error(error);
            //     } finally {
            //         return;
            //     }
            // }

            //bet page
            //if (action == `/engine/function/bet.php`) {

            // console.log(data.response && data.response.return_url);
            if (data.response && data.response.return_url) {
                window.location.assign(data.response.return_url);
                return;
            }


            //}
            //console.log(data);
            if (data.content_html) {
                //console.log(data.content_html);
                $('body').append(data.content_html);
            }

            //document.body.insertAdjacentHTML('beforeend', data);
        } catch (error) {
            console.error(error);
        }
    });
    posting.fail(function (jqXHR, textStatus, error) {
        console.error(`error posting`, action);
        console.error(jqXHR, textStatus, error);
        $('.response_block_close_js_answer').remove();
        $('div.blackout').remove();
    })
    posting.always(function () {
        // console.log("finished");
        //включаем кнопку и убираем анимацию лоада
        button.show();
        button.prop("disabled", false);
        button.removeClass('loading');
        button.find('.loading-div__mini_b').remove();
        // if ($('.mCustomScrollbar')) {
        //     $('.mCustomScrollbar').mCustomScrollbar();
        // }
        toSVG();
    });

    if ($(this).hasClass('response_block_close_js')) {
        //console.log('closing by class');
        $('div.blackout').remove();
        formdiv.remove();
        return;
    }
});